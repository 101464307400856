import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import MXHubLogo from "../assets/images/hub-logo.svg";
import DarkMXHubLogo from "../assets/images/hub-logo-white.svg";
import SearchIcon from "../assets/images/search-icon.svg";
import api from "./api";
import utils from "./utils";
import { useOktaAuth } from "@okta/okta-react";

function searchBar() {
  return {
    __html: `<div id="auto">
<div ng-controller="SearchautoController">
<div bind-html-compile="autocompleteHtml"></div>
</div>
</div>`,
  };
}

const SearchDiv = styled.div``;

const FooterWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.sectionColor};

  @media (max-width: 900px) {
    display: none;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 50px;
  max-width: 1500px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 20px;

  @media (max-width: 1600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const MXCompanyInfoCol = styled.div`
  max-width: 213px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-right: 50px;
`;
const FooterCol = styled.div`
  position: relative;
  line-height: 32px;
  margin: 0px 100px 0 0;
  font-size: 16px;

  @media (max-width: 1000px) {
    margin: 20px;
  }
`;
const ColTitle = styled.p`
  position: absolute;
  color: ${({ theme }) => theme.textColor};
  transform: rotate(-90deg);
  left: -70px;
  top: 43px;
  text-align: right;
  width: 100px;
  margin: 0;
  text-transform: uppercase;
  font-size: 12px;
`;
const FooterCTA = styled.a`
  background: ${({ theme }) => theme.blue};
  color: #fff;
  padding: 8px 20px;
  font-size: 14px;
  border-radius: 25px;
  text-decoration: none;
  text-transform: uppercase;
`;
const FooterCopyright = styled.p`
  color: ${({ theme }) => theme.textColor};
  font-size: 12px;
  text-transform: uppercase;
`;
const FooterText = styled.p`
  color: ${({ theme }) => theme.textColor};
`;
const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.textColor};
  padding: 0 5px;
  display: block;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
  &.disabled {
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }
`;

const FooterAnchor = styled.a`
  color: ${({ theme }) => theme.textColor};
  padding: 0 5px;
  display: block;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
  &.disabled {
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }
`;

const CompanyDetails = styled.p`
  color: ${({ theme }) => theme.textColor};
  margin-top: 0;
  line-height: 1.7;
`;

const LinkSection = styled.div`
  padding: 0 10px 20px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: left;

  @media (max-width: 1600px) {
    padding: 0 0 50px 0;
  }

  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    justify-content: center;
    align-items: center;
  }
`;

const FooterSearch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 475px;
  box-sizing: border-box;
  position: relative;

  form {
    width: 90%;
    right: 0;
    text-align: right;
    posi
  }

  @media (max-width: 1600px) {
    padding:50px 0;
    align-items: center;
  }
`;
const SearchBtn = styled.img`
  width: 15px;
  height: 15px;
  position: relative;
  top: 27px;
  right: 20px;
`;
const FooterInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  height: 30px;
  border-radius: 15px;
  margin-bottom: 10px;
  padding-left: 20px;
  border: none;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a5a5a5;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a5a5a5;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #a5a5a5;
  }
`;

const HubLogo = styled.img`
  padding: 0;
  padding-left: 100px;
  max-height: 100px;
  height: auto;
  align-self: center;
  margin-bottom: 10px;

  @media (max-width: 1600px) {
    padding-left: unset;
  }
`;

const FooterDivider = styled.div`
  position: absolute;
  left: 0;
  content: "";
  background: ${({ theme }) => theme.hoverBackground};
  height: 200px;
  width: 2px;

  @media (max-width: 1600px) {
    display: none;
  }
`;

const LinkSwitch = ({ link }) => {
  if (!utils.isExternalLink(link.link)) {
    return (
      <FooterLink
        onClick={() => utils.reloadIfOnSamePage(link.link)}
        className={link.link == null && "disabled"}
        to={link.link}
      >
        {link.label}
      </FooterLink>
    );
  } else {
    return (
      <FooterAnchor
        className={link.link == null && "disabled"}
        href={link.link}
        target={"_blank"}
      >
        {link.label}
      </FooterAnchor>
    );
  }
};

function Footer({ theme, homeBg }) {
  const { authState, oktaAuth } = useOktaAuth();
  const [libraryLinks, setLibraryLinks] = useState([]);
  const [requestLinks, setRequestLinks] = useState([]);
  const [quickLinks, setQuickLinks] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let filterParams = "limit=-1";
      const FooterData = await api.collection("footer", oktaAuth, filterParams);
      setLibraryLinks(FooterData.library_links);
      setRequestLinks(FooterData.request_links);
      setQuickLinks(FooterData.quicklinks_links);
    }
    fetchData();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    if (e.target.id == "searchBtn") {
      if (e.target.nextSibling.value) {
        let searchTerm = e.target.nextSibling.value;
        window.location.href = `/search?searchString=${searchTerm}`;
      } else {
        return;
      }
    } else {
      let searchTerm = e.target[0].value;
      window.location.href = `/search?searchString=${searchTerm}`;
    }
  }

  const logoImg = theme.name == "light" ? DarkMXHubLogo : MXHubLogo;
  const homePageBackgroundStyle = {
    background: theme.homeFooterBgColor,
  };
  return (
    <FooterWrapper style={homeBg ? homePageBackgroundStyle : {}}>
      <FooterContainer>
        <LinkSection>
          <FooterCol>
            <ColTitle>MX</ColTitle>
            <CompanyDetails>
              (801) 669-5500 <br />
              3401 N Thanksgiving Way
              <br />
              #500, Lehi, UT 84043
            </CompanyDetails>
            {/* <FooterCTA
              href="https://exmxtechnologies.qualtrics.com/jfe/form/SV_6EAEGH4B0AcOsqG"
              target="_blank"
            >
              Submit Feedback
            </FooterCTA> */}
          </FooterCol>
          <FooterCol>
            <ColTitle>Library</ColTitle>
            {libraryLinks.slice(0, 7).map((link, i) => {
              return <LinkSwitch link={link} key={i} />;
            })}
          </FooterCol>
          <FooterCol>
            <ColTitle>Requests</ColTitle>
            {requestLinks.slice(0, 7).map((link, i) => {
              return <LinkSwitch link={link} key={i} />;
            })}
          </FooterCol>
          <FooterCol>
            <ColTitle>Quicklinks</ColTitle>
            {quickLinks.slice(0, 7).map((link, i) => {
              return <LinkSwitch link={link} key={i} />;
            })}
          </FooterCol>
        </LinkSection>

        <FooterDivider />
        <Link to={"/"}>
          <HubLogo src={logoImg} />
        </Link>

        {/* <FooterCopyright>© MX Technologies 2023</FooterCopyright> */}
      </FooterContainer>
    </FooterWrapper>
  );
}

export default withTheme(Footer);
